<template>
  <div>
    <Confirmation ref="Confirmation"></Confirmation>
    <datatable-heading
      :title="$t('menu.work-flow-step-history')"
      :changePageSize="changePageSize"
      :searchChange="searchChange"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
    >
    </datatable-heading>

    <b-row>
      <b-colxx xxs="12">
        <b-card>
          <vuetable
            ref="vuetable"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
            :reactive-api-url="false"
            :fields="fields"
            :css="css.table"
            pagination-path
            :row-class="onRowClass"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="rowClicked"
            @vuetable:cell-rightclicked="rightClicked"
            @vuetable:load-error="handleLoadError"
          >
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
          class="mt-4"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        />
      </b-colxx>
    </b-row>

    <v-contextmenu ref="contextmenu">
       <v-contextmenu-item @click="onContextMenuAction('view')">
        <i class="simple-icon-docs" />
        <span>View</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>

<script>
import Confirmation from '../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../constants/config'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import moment from 'moment/moment.js'
import _ from 'lodash'

// so we can set our messages in the layout if needed
import { mapMutations, mapActions } from 'vuex'
export default {
  props: {
    companyID: {
      type: Number,
      default: 0
    }
  },

  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    Confirmation: Confirmation
  },
  mixins: [],
  data () {
    var _idfield = 'workFlowStepHistoryID'
    return {
      isLoad: false,
      apiBase: BeaconapiUrl + `/api/company/${this.companyID}/work-flow-step-history`,
      sort: '',
      page: 1,
      perPage: 50,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,

      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Work Flow Step History ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        {
          name: 'stepName',
          sortField: 'stepName',
          title: 'Step Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        {
          name: 'stepDescription',
          sortField: 'stepDescription',
          title: 'Description',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        {
          name: 'workFlowType',
          sortField: 'workFlowType',
          title: 'Work Flow Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        {
          name: 'createdBy',
          sortField: 'createdBy',
          title: 'Created By',
          titleClass: '',
          dataClass: 'text-muted',
          width: '12%'
        },
        {
          name: 'created',
          sortField: 'created',
          title: 'Created',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          callback: this.formatDate
        }
      ]
    }
  },

  methods: {
    ...mapMutations(['setDangerMessage', 'setAlertMessage', 'setInfoMessage', 'appendInfoMessage']),
    // Vue Table plumbing methods (standardized)

    makeQueryParams (sortOrder, currentPage, perPage) {
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },

    formatDate (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value, 'YYYY-MM-DD, hh:mm A').format('MM/DD/YYYY, hh:mm A')
      }
    }
  }
}
</script>
